import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Modal,
  Slider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useModalCreditRequest from '../hooks/useModalCreditRequest';
import ModalCreditRequest from './ModalCreditRequest';
import TarsService from '../services/TarsService';
import imgSelfie from '../Images/selfie.png';
import imgFrontPicture from '../Images/frontid.png';
import imgBackPicture from '../Images/backid.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useCreditRequestAuth } from '../Context/useCreditRequestAuth';
import dayjs from 'dayjs';
import DocumentAutoCapture from './DocumentAutoCapture';
import FaceAutoCapture from './FaceAutoCapture';
import { CreateUserAgora } from '../services/SoraService';
import CustomBox from 'src/components/CustomBox';
import '../StyleSheets/SwalStyle.css';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../utils/cropImage';
import Iconify from '../components/iconify';
import imgPendingManualValidation from '../Images/pending-manual-validation.png';
import { useGlobalProvider } from '../components/GlobalProvider'
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FaceDetection from '@mediapipe/face_detection';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const IdentityValidation = ({ handleChangeStep, handleCloseModal }) => {
  const TRIES_FOR_ACTIVATE_QUESTION = 2;// UNO MENOS DE LO QUE SE REQUIERA. EJ: SI SE QUIEREN 2 INTENTOS ENTONCES PONER 1
  const TRIES_FOR_DEACTIVATE_PHOTOS = 4;// UNO MENOS DE LO QUE SE REQUIERA. EJ: SI SE QUIEREN 5 INTENTOS ENTONCES PONER 4
  const [faceInDocument, setFaceInDocument] = useState(true);
  const [isOpenModalPicture, openModalPicture, closeModalPicture] =
    useModalCreditRequest(false);
  const navigate = useNavigate();
  const [selfiePicture, setSelfiePicture] = useState('');
  const [selfiePictureFile, setSelfiePictureFile] = useState(null);
  const [showDivSelfiePicture, setShowDivSelfiePicture] = useState(false);
  const [sending, setSending] = useState(false);
  const [idFrontPicture, setIdFrontPicture] = useState('');
  const [idFrontPictureFile, setIdFrontPictureFile] = useState(null);
  const [showDivIdFrontPicture, setShowDivIdFrontPicture] = useState(false);
  const [idBackPicture, setIdBackPicture] = useState('');
  const [idBackPictureFile, setIdBackPictureFile] = useState(null);
  const [showDivIdBackPicture, setShowDivIdBackPicture] = useState(false);
  const {
    SetManualValidationCustomerFromJWT,
    SavePhotoCustomerFromJWT,
    IdentityValidation
  } = TarsService();
  const MySwal = withReactContent(Swal);
  const { logout, clearRequest, getStorage, setStorage } =
    useCreditRequestAuth();
  const [myCanvas, setMyCanvas] = useState(null);
  const myCanvasRef = useCallback((node) => setMyCanvas(node), []);
  const [myCanvas2, setMyCanvas2] = useState(null);
  const myCanvasRef2 = useCallback((node) => setMyCanvas2(node), []);
  const [myCanvas3, setMyCanvas3] = useState(null);
  const myCanvasRef3 = useCallback((node) => setMyCanvas3(node), []);
  const request = getStorage('request');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppingImageSrc, setCroppingImageSrc] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [cropIdFront, setCropIdFront] = useState({ x: 0, y: 0 });
  const [zoomIdFront, setZoomIdFront] = useState(1);
  const [croppedAreaPixelsIdFront, setCroppedAreaPixelsIdFront] = useState(null);
  const [croppingImageSrcIdFront, setCroppingImageSrcIdFront] = useState(null);
  const [isCroppingIdFront, setIsCroppingIdFront] = useState(false);
  const [cropIdBack, setCropIdBack] = useState({ x: 0, y: 0 });
  const [zoomIdBack, setZoomIdBack] = useState(1);
  const [croppedAreaPixelsIdBack, setCroppedAreaPixelsIdBack] = useState(null);
  const [croppingImageSrcIdBack, setCroppingImageSrcIdBack] = useState(null);
  const [isCroppingIdBack, setIsCroppingIdBack] = useState(false);
  const { posData } = useGlobalProvider();
  const [tryCount, setTryCount] = useState(0);
  // const [faceSimilarityS, setFaceSimilarityS] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [rotation2, setRotation2] = useState(0);
  const [rotation3, setRotation3] = useState(0);

  useEffect(() => {
    setSending(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        setTimeout(() => {
          setSending(false);
          MySwal.fire({
            html: `<b>Para el proceso de toma de fotos ten en cuenta las siguientes indicaciones a la hora de tomarte la selfie 🤳</b>
                     <ul style="text-align:left;font-size: 15px;">
                     <li>No usar tapabocas, gafas, gorras ni audífonos</li>
                     <li>La foto debe estar enfocada</li>
                     <li>La foto debe tener buena iluminación</li>
                     <li>La foto NO debe tener brillos</li></ul>`,
            confirmButtonText: 'Entendido',
            confirmButtonColor: '#00a9eb',
            customClass: {
              container: 'swal2-container',
            },
          });
        }, 4000);
      })
      .catch(() => {
        setSending(false);
        MySwal.fire({
          title: 'SE NECESITAN PERMISOS PARA ACCEDER A LA CÁMARA',
          html: 'Por favor permite el uso de la cámara y refresca la página',
          icon: 'error',
          allowOutsideClick: false,
          customClass: {
            container: 'swal2-container',
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (idFrontPicture) {

      let faceDetection = new FaceDetection.FaceDetection({
        locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      })

      faceDetection.setOptions({ model: 'short' })

      const myImage = new Image();
      myImage.src = idFrontPicture;

      faceDetection.send({ image: myImage }).catch(error => {
        console.log("ERROR CONTROLADO CAPTURA ROSTRO")
        console.log(error)
        setFaceInDocument(true)
      })

      faceDetection.onResults(res => {
        if (res.detections.length > 0)
          setFaceInDocument(true)
        else
          setFaceInDocument(false)
      })
    }
  }, [idFrontPicture]);



  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (
      let offset = 0;
      offset < byteCharacters.length;
      offset += sliceSize
    ) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const validatePictures = async () => {

    if (!faceInDocument) {

      Swal.fire({
        title: "No se detectó el rostro en la cédula frontal",
        html: "Asegúrate que el rostro de la cédula sea totalmente visible, evita reflejos y hologramas que dificulten la comparación de rostros. Usa buena iluminación y mantén firme la cédula para mayor nitidez.",
        showCancelButton: false,

        confirmButtonText: 'Aceptar',
        confirmButtonColor: "#00a9eb",
        icon: 'warning'
      })

      return
    }

    setSending(true);
    let image1;
    let image2;
    let image3;

    if (selfiePictureFile) {
      image1 = selfiePictureFile;
    } else {
      image1 = new File(
        [b64toBlob(selfiePicture.split(',')[1], 'image/jpeg')],
        `selfie_device_${dayjs().format('YYYYMMDD_HH_mm_ss')}.jpg`,
        {
          type: 'image/jpeg',
        },
      );
    }

    if (idFrontPictureFile) {
      image2 = idFrontPictureFile;
    } else {
      image2 = new File(
        [b64toBlob(idFrontPicture.split(',')[1], 'image/jpeg')],
        `front_device_${dayjs().format('YYYYMMDD_HH_mm_ss')}.jpg`,
        {
          type: 'image/jpeg',
        },
      );
    }

    if (idBackPictureFile) {
      image3 = idBackPictureFile;
    } else {
      image3 = new File(
        [b64toBlob(idBackPicture.split(',')[1], 'image/jpeg')],
        `back_device_${dayjs().format('YYYYMMDD_HH_mm_ss')}.jpg`,
        {
          type: 'image/jpeg',
        },
      );
    }

    const bodyFormData1 = new FormData();

    bodyFormData1.append('Selfie.File', image1);
    bodyFormData1.append('CedulaFront.File', image2);
    bodyFormData1.append('CedulaBack.File', image3);

    bodyFormData1.append('Cedula', request.cusDocument);
    bodyFormData1.append(
      'cusFullName',
      `${request.cusFirstName} ${request.cusSecondName} ${request.cusFirstSurname} ${request.cusSecondSurname}`,
    );
    bodyFormData1.append('Email', request.cusEmail);

    SavePhotoCustomerFromJWT(bodyFormData1).then((res) => {
      if (res.status === 200) {
        IdentityValidation({
          documentType: request.cusDocumentType,
          document: request.cusDocument,
          firstName: `${request.cusFirstName} ${request.cusSecondName}`,
          lastName: `${request.cusFirstSurname} ${request.cusSecondSurname}`,
          selfiePicture: selfiePicture.split(",")[1],
          frontPicture: idFrontPicture.split(",")[1],
          gender: request.cusGender,
          birthDate: `${request.year}-${request.month}-${request.day}`,
          backPicture: idBackPicture.split(",")[1],
          posId: posData.posId
        }).then((res2) => {
          if (res2.status === 200) {
            const data = JSON.parse(res2.data.data);

            const { faceSimilarity, faceMatch, identificationMatch, nameMatch } = data;
            // setFaceSimilarityS(Number(faceSimilarity));
            setTryCount(i => i + 1);
            if (!faceMatch || !identificationMatch || !nameMatch) {
              setSending(false);
              MySwal.fire({
                html: (
                  <ModalErrorIV
                    msg="Validación de identidad fallida"
                    faceSimilarity={Number(faceSimilarity)}
                    faceMatch={faceMatch}
                    identificationMatch={identificationMatch}
                    nameMatch={nameMatch}
                    modify
                  />
                ),
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: false,
                showDenyButton: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  container: 'swal2-container',
                },
              });
            }
            // else if (!nameMatch) {
            //   setSending(false);
            //   MySwal.fire({
            //     html: (
            //       <ModalErrorIV
            //         msg="Los nombres digitados no coinciden con los nombres de la foto"
            //         faceSimilarity={Number(faceSimilarity)}
            //         modify
            //       />
            //     ),
            //     icon: 'error',
            //     showCancelButton: false,
            //     showConfirmButton: false,
            //     showDenyButton: false,
            //     allowEnterKey: false,
            //     allowEscapeKey: false,
            //     allowOutsideClick: false,
            //     customClass: {
            //       container: 'swal2-container',
            //     },
            //   });
            // } else if (!faceMatch) {
            //   setSending(false);
            //   MySwal.fire({
            //     html: (
            //       <ModalErrorIV
            //         msg={`La foto de la selfie y la foto del documento tienen una similitud de ${faceSimilarity} la cual NO es suficiente para superar la validación de identidad`}
            //         faceSimilarity={Number(faceSimilarity)}
            //         modify={false}
            //       />
            //     ),
            //     icon: 'error',
            //     showCancelButton: false,
            //     showConfirmButton: false,
            //     showDenyButton: false,
            //     allowEnterKey: false,
            //     allowEscapeKey: false,
            //     allowOutsideClick: false,
            //     customClass: {
            //       container: 'swal2-container',
            //     },
            //   });
            // } 
            else if (identificationMatch && faceMatch && nameMatch) {


              setStorage("request", {
                ...request,
                state: 9
              });

              handleChangeStep(9);

              //SE COMENTA LA CREACION DE USUARIO YA QUE SE HIZO EN EL PASO DE DATOS SOCIODEMOGRAFICOS
              // CreateUserAgora({ token: data.token }).then(resjwt => {
              //   setSending(false);
              //   if (resjwt.status === 200 || resjwt.status === 201) {

              //     setRotation(0)
              //     setRotation2(0)
              //     setRotation3(0)

              //     setStorage("request", {
              //       ...request,
              //       state: 9
              //     });

              //     handleChangeStep(9);

              //   } else {

              //     MySwal.fire({
              //       html: resjwt.data.message,
              //       icon: 'error'
              //     })
              //   }

              // })

            }
          } else {
            setSending(false);
            MySwal.fire({
              html: res2.data.message,
              icon: 'error',
              customClass: {
                container: 'swal2-container',
              },
            });
          }
        });
      } else {
        setSending(false);
        MySwal.fire({
          html: res.data.message,
          icon: 'error',
          customClass: {
            container: 'swal2-container',
          },
        });
      }
    });
  };

  const handleFacePhotoTaken = (imageData) => {
    const imageUrl = URL.createObjectURL(imageData.image);

    const context = myCanvas3.getContext('2d');

    const image = new Image();
    image.src = imageUrl;

    image.onload = function () {
      const cropX = imageData.data.detection.topLeft.x - 150;
      const cropY = imageData.data.detection.topLeft.y - 200;

      const cropWidth =
        imageData.data.detection.bottomRight.x -
        imageData.data.detection.topLeft.x +
        300;
      const cropHeight =
        imageData.data.detection.bottomRight.y -
        imageData.data.detection.topLeft.y +
        300;

      myCanvas3.width = cropWidth;
      myCanvas3.height = cropHeight;

      context.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        myCanvas3.width,
        myCanvas3.height,
      );
      setSelfiePicture(myCanvas3.toDataURL('image/jpeg'));
      setShowDivSelfiePicture(false);

      setSending(true);

      setTimeout(() => {
        setSending(false);

        closeModalPicture();
      }, 2000);
    };
  };

  const handleDocumentPhotoTaken = (imageData) => {
    const imageUrl = URL.createObjectURL(imageData.image);

    if (showDivIdFrontPicture) {
      const context = myCanvas.getContext('2d');

      const image = new Image();
      image.src = imageUrl;

      image.onload = function () {
        const cropX = imageData.data.detection.topLeft.x;
        const cropY = imageData.data.detection.topLeft.y;
        const cropWidth =
          imageData.data.detection.topRight.x -
          imageData.data.detection.topLeft.x;
        const cropHeight =
          imageData.data.detection.bottomRight.y -
          imageData.data.detection.topRight.y;

        myCanvas.width = cropWidth;
        myCanvas.height = cropHeight;

        context.drawImage(
          image,
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          0,
          0,
          myCanvas.width,
          myCanvas.height,
        );
        setIdFrontPicture(myCanvas.toDataURL('image/jpeg'));
        setShowDivIdFrontPicture(false);

        setSending(true);

        setTimeout(() => {
          setSending(false);

          closeModalPicture();
        }, 2000);
      };
    } else {
      const context = myCanvas2.getContext('2d');

      const image = new Image();
      image.src = imageUrl;

      image.onload = function () {
        const cropX = imageData.data.detection.topLeft.x;
        const cropY = imageData.data.detection.topLeft.y;
        const cropWidth =
          imageData.data.detection.topRight.x -
          imageData.data.detection.topLeft.x;
        const cropHeight =
          imageData.data.detection.bottomRight.y -
          imageData.data.detection.topRight.y;

        myCanvas2.width = cropWidth;
        myCanvas2.height = cropHeight;

        context.drawImage(
          image,
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          0,
          0,
          myCanvas2.width,
          myCanvas2.height,
        );
        setIdBackPicture(myCanvas2.toDataURL('image/jpeg'));
        setShowDivIdBackPicture(false);

        setSending(true);

        setTimeout(() => {
          setSending(false);

          closeModalPicture();
        }, 2000);
      };
    }
  };

  const handleError = useCallback((error) => {
    alert(error);
  }, []);

  const ManualValidation = () => {
    setSending(true);
    SetManualValidationCustomerFromJWT(
      request.cusEmail,
      `${request.cusFirstName} ${request.cusSecondName} ${request.cusFirstSurname} ${request.cusSecondSurname}`,
      request.cusDocument,
    ).then((res) => {
      setSending(false);
      if (res.status === 200) {
        MySwal.fire({
          html: `<b>Estamos validando tu información</b>
                                <br/>
                                <br/>
                                <img style="width:100px; margin: 0 auto;" src="` + String(imgPendingManualValidation) + `"/>
                                <br/>
                                <br/>
                                <p>En estos momentos nuestro equipo se encuentra validando la información que acabas de suministrarnos, en breve nos comunicaremos con el cliente para continuar con el proceso.</p>
                                `,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#00a9eb',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            container: 'swal2-container',
          },
        });

        setStorage("request", {
          ...request,
          state: 0
        });

        handleChangeStep(0);
        logout();
        clearRequest();
      } else {
        MySwal.fire({
          html: res.data.message,
          icon: 'error',
          customClass: {
            container: 'swal2-container',
          },
        });
      }
    });
  };

  const ModalErrorIV = ({ msg, modify, faceSimilarity, faceMatch, identificationMatch, nameMatch }) => (
    <>
      <b>{msg}</b>
      <br />
      <br />

      <table style={{ margin: "0 auto" }}>
        <tr>
          <td style={{ textAlign: "left" }}>Coincidencia del rostro ({Math.round(faceSimilarity)}%)&nbsp;</td>
          <td> {faceMatch ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />} </td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Coincidencia de número de identificación&nbsp;</td>
          <td> {identificationMatch ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Coincidencia de Nombres&nbsp;</td>
          <td> {nameMatch ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</td>
        </tr>
      </table>

      {/* <span style={{ display: "inline-flex", alignItems: "center" }}><b>Coincidencia del rostro: </b>{faceMatch ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />} ({Math.round(faceSimilarity)}%)</span> <br />
      <span style={{ display: "inline-flex", alignItems: "center" }}><b>Número de identificación: </b>{identificationMatch ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</span> <br />
      <span style={{ display: "inline-flex", alignItems: "center" }}><b>Nombres: </b>{nameMatch ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</span>
      */}
      <br />
      <br />
      Puedes escoger alguna de las siguientes opciones para continuar tu proceso:
      <br />
      <br />
      <table style={{ width: '100%' }}>
        <tbody>

          {tryCount <= TRIES_FOR_DEACTIVATE_PHOTOS && <tr>
            <td style={{ padding: '5px' }}>
              <Button
                type="submit"
                style={{ width: '250px', backgroundColor: "#00a9eb" }}
                variant="contained"

                onClick={() => {
                  // setSelfiePicture('');
                  // setIdFrontPicture('');
                  // setIdBackPicture('');
                  // setSelfiePictureFile(null);
                  // setIdFrontPictureFile(null);
                  // setIdBackPictureFile(null);
                  MySwal.close();
                }}
              >
                Tomar las fotos nuevamente
              </Button>
            </td>
          </tr>}

          <tr>
            <td style={{ padding: '5px' }}>
              <Button
                type="submit"
                style={{ width: '250px', backgroundColor: "#00a9eb" }}
                variant="contained"

                onClick={() => {
                  window.open(`https://wa.me/${encodeURI('+573011633330')}`);
                }}
              >
                Enviar fotos por WhatsApp
              </Button>
            </td>
          </tr>

          {modify && (
            <tr>
              <td style={{ padding: '5px' }}>
                <Button

                  style={{ width: '250px', backgroundColor: "#00a9eb" }}
                  variant="contained"

                  onClick={() => {
                    setStorage('request', {
                      ...request,
                      state: 0,
                      updateCustomer: true,
                    });
                    MySwal.close();
                    handleChangeStep(0);
                  }}
                >
                  Modificar datos
                </Button>
              </td>
            </tr>
          )}

          {faceSimilarity > 20 && <tr>
            <td style={{ padding: '5px' }}>
              <Button

                style={{ width: '250px', backgroundColor: "#00a9eb" }}
                variant="contained"

                onClick={() => {
                  setStorage('request', {
                    ...request,
                    state: 13,
                  });
                  MySwal.close();
                  handleChangeStep(13);
                }}
              >
                Validación mediante preguntas de seguridad
              </Button>
            </td>
          </tr>}

          <tr>
            <td style={{ padding: '5px' }}>
              <Button
                type="submit"
                style={{ width: '250px', backgroundColor: "#00a9eb" }}
                variant="contained"

                onClick={() => {
                  MySwal.close();
                  ManualValidation();
                }}
              >
                Solicitar revisión humana
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  // Manejo de carga de archivos desde el dispositivo
  const handleSelfieUpload = async (event) => {

    const file = event.target.files[0];

    if (file) {
      setSelfiePictureFile(file);
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   setSelfiePicture(reader.result);
      // };
      // reader.readAsDataURL(file);
      const imageDataUrl = await readFile(file);
      setCroppingImageSrc(imageDataUrl);
      setIsCropping(true);
    }
  };

  const handleSelfieDelete = (event) => {
    setSelfiePicture('');
  };

  const handleIdFrontUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdFrontPictureFile(file);
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   setIdFrontPicture(reader.result);
      // };
      // reader.readAsDataURL(file);
      const imageDataUrl = await readFile(file);
      setCroppingImageSrcIdFront(imageDataUrl);
      setIsCroppingIdFront(true);
    }
  };

  const handleIdFrontDelete = (event) => {
    setIdFrontPicture('');
  };

  const handleIdBackUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdBackPictureFile(file);
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   setIdBackPicture(reader.result);
      // };
      // reader.readAsDataURL(file);
      const imageDataUrl = await readFile(file);
      setCroppingImageSrcIdBack(imageDataUrl);
      setIsCroppingIdBack(true);
    }
  };

  const handleIdBackDelete = (event) => {
    setIdBackPicture('');
  };

  //Manejo de recorte de imagenes
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.readAsDataURL(file);
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(croppingImageSrc, croppedAreaPixels, rotation);
      // Actualiza el estado correspondiente con la imagen recortada
      setSelfiePicture(croppedImage);
      setIsCropping(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppingImageSrc, croppedAreaPixels]);

  const onCropCompleteIdFront = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixelsIdFront(croppedAreaPixels);
  }, []);

  const showCroppedImageIdFront = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(croppingImageSrcIdFront, croppedAreaPixelsIdFront, rotation2);
      setIdFrontPicture(croppedImage);
      setIsCroppingIdFront(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppingImageSrcIdFront, croppedAreaPixelsIdFront]);

  const onCropCompleteIdBack = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixelsIdBack(croppedAreaPixels);
  }, []);

  const showCroppedImageIdBack = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(croppingImageSrcIdBack, croppedAreaPixelsIdBack, rotation3);
      setIdBackPicture(croppedImage);
      setIsCroppingIdBack(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppingImageSrcIdBack, croppedAreaPixelsIdBack]);

  return (

    <CustomBox
      sx={{
        width: '90%',
      }}
    >
      <Modal open={isCropping} onClose={() => setIsCropping(false)}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxWidth: '70%',
            height: '70%',
            maxHeight: '70%',
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <Cropper
            image={croppingImageSrc}
            crop={crop}
            zoom={zoom}
            rotation={rotation}

            aspect={8 / 9}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, value) => setZoom(value)}
            sx={{ mt: 2 }}
          />
          <Button variant="contained" onClick={() => { setRotation(r => r + 90) }} sx={{ mt: 2 }} endIcon={<RotateRightIcon />}>
            Rotar
          </Button>
          <Button variant="contained" onClick={showCroppedImage} style={{ position: "absolute", bottom: "10px", left: "0", right: "0", width: "200px", margin: "0 auto" }}>
            Aceptar
          </Button>
        </div>
      </Modal>

      <Modal open={isCroppingIdFront} onClose={() => setIsCroppingIdFront(false)}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxWidth: '70%',
            height: '70%',
            maxHeight: '70%',
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <Cropper
            image={croppingImageSrcIdFront}
            crop={cropIdFront}
            zoom={zoomIdFront}
            rotation={rotation2}
            aspect={8 / 5} // Ajusta la relación de aspecto según el documento
            onCropChange={setCropIdFront}
            onZoomChange={setZoomIdFront}
            onCropComplete={onCropCompleteIdFront}
          />
          <Slider
            value={zoomIdFront}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, value) => setZoomIdFront(value)}
            sx={{ mt: 2 }}
          />
          <Button variant="contained" onClick={() => { setRotation2(r => r + 90) }} sx={{ mt: 2 }} endIcon={<RotateRightIcon />}>
            Rotar
          </Button>
          <Button variant="contained" onClick={showCroppedImageIdFront} style={{ position: "absolute", bottom: "10px", left: "0", right: "0", width: "200px", margin: "0 auto" }}>
            Aceptar
          </Button>
        </div>
      </Modal>

      <Modal open={isCroppingIdBack} onClose={() => setIsCroppingIdBack(false)}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxWidth: '70%',
            height: '70%',
            maxHeight: '70%',
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <Cropper
            image={croppingImageSrcIdBack}
            crop={cropIdBack}
            zoom={zoomIdBack}
            aspect={8 / 5} // Ajusta la relación de aspecto según el documento
            rotation={rotation3}
            onCropChange={setCropIdBack}
            onZoomChange={setZoomIdBack}
            onCropComplete={onCropCompleteIdBack}
          />
          <Slider
            value={zoomIdBack}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, value) => setZoomIdBack(value)}
            sx={{ mt: 2 }}
          />
          <Button variant="contained" onClick={() => { setRotation3(r => r + 90) }} sx={{ mt: 2 }} endIcon={<RotateRightIcon />}>
            Rotar
          </Button>
          <Button variant="contained" onClick={showCroppedImageIdBack} style={{ position: "absolute", bottom: "10px", left: "0", right: "0", width: "200px", margin: "0 auto" }}>
            Aceptar
          </Button>
        </div>
      </Modal>

      <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }} >

        <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
          <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="black" sx={{ fontSize: '13px' }}>
            {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}
          </Typography>
          <Typography variant="body2" color="gray" sx={{ fontSize: '13px' }}>
            {request.cusDocumentTypeTemp} {request.cusDocument}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h5" color="textSecondary">
            <b style={{ color: 'black' }}>4</b>/4
          </Typography>
        </Grid>

        {/* Selfie */}
        <Grid item xs={12} sm={12} md={12} xl={12} sx={{
          // display: 'flex',

          //  alignItems: 'center',
          //  justifyContent: 'center',
          // // px: 2,
          maxWidth: { xs: '100%', sm: '100%', md: '100%' },
          margin: '0 auto',
        }}
        >
          <Box
            sx={{
              // display: 'flex',
              // flexDirection: 'column',
              textAlign: "center",
              margin: "0 auto",
              alignItems: 'center',
              backgroundColor: '#efefef',
              borderRadius: 2,
              border: 1,
              borderColor: 'black',
              p: 2,
              // cursor: 'pointer',
              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' }
            }}
          // onClick={
          //   !selfiePicture
          //     ? () => {
          //       setShowDivSelfiePicture(true);
          //       setShowDivIdFrontPicture(false);
          //       setShowDivIdBackPicture(false);
          //       openModalPicture();
          //     }
          //     : undefined
          // }
          >
            <img
              src={!selfiePicture ? imgSelfie : selfiePicture}
              className="imgPicture"
              style={{
                width: !selfiePicture ? '70px' : '200px',
                margin: "0 auto"
              }}
              alt="Selfie"
            />
            <canvas
              id="my-canvas3"
              ref={myCanvasRef3}
              style={{ display: 'none' }}
            />
            {selfiePicture && (
              // <i
              //   className="bi bi-trash manual-validation-icon"
              //   onClick={(e) => {
              //     e.stopPropagation();
              //     setSelfiePicture('');
              //     setSelfiePictureFile(null);
              //   }}
              // />
              <>
                <br />
                <Iconify sx={{ width: "30px", height: "30px" }} onClick={(e) => {
                  e.stopPropagation();
                  setSelfiePicture('');
                  // setSelfiePictureFile(null);
                }} icon="eva:trash-2-outline" />
              </>
            )}
            <br />
            {!selfiePicture ? 'Selfie' : ''}

            <br />
            <Button
              disabled={selfiePicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "80%", textAlign: "center", margin: "0 auto" }}
              onClick={() => {

                setShowDivSelfiePicture(true);
                setShowDivIdFrontPicture(false);
                setShowDivIdBackPicture(false);
                openModalPicture();
              }}
            >
              Tomar Selfie con la camara

            </Button>
            <br />

            <Button
              disabled={selfiePicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "80%", textAlign: "center" }}
            >
              Subir Selfie desde el dispositivo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleSelfieUpload}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
            </Button>

          </Box>

          {/* Botón para cargar foto desde el dispositivo */}

          {/* <Box
            sx={{
              // display: 'flex',
              // flexDirection: 'column',
              textAlign: "center",
              margin: "0 auto",
              alignItems: 'center',

              pt: 2,
              pb: 2,
              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' }
            }}

          >

            <Button
              disabled={selfiePicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "250px", textAlign: "center", margin: "0 auto" }}
              onClick={() => {

                setShowDivSelfiePicture(true);
                setShowDivIdFrontPicture(false);
                setShowDivIdBackPicture(false);
                openModalPicture();
              }}
            >
              Tomar Selfie con la camara

            </Button>
            <br />

            <Button
              disabled={selfiePicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "250px", textAlign: "center" }}
            >
              Subir Selfie desde el dispositivo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleSelfieUpload}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
            </Button>
          </Box> */}

          {/* <Button
            disabled={!selfiePicture ? true : false}
            variant="contained"
            component="label"
            sx={{ mt: 1 }}
            onClick={handleSelfieDelete}
          >
            Eliminar Selfie
          </Button> */}
        </Grid>


        {/* Foto frontal de la cédula */}
        <Grid item xs={12} sm={12} md={12} xl={12}
          sx={{
            // display: 'flex',


            // px: 2,
            maxWidth: { xs: '100%', sm: '100%', md: '100%' },
            margin: '0 auto',
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              margin: "0 auto",
              alignItems: 'center',
              backgroundColor: '#efefef',
              borderRadius: 2,
              border: 1,
              borderColor: faceInDocument ? "black" : "red",
              p: 2,
              // cursor: 'pointer',
              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' }
            }}
          // onClick={
          //   !idFrontPicture
          //     ? () => {
          //       setShowDivSelfiePicture(false);
          //       setShowDivIdFrontPicture(true);
          //       setShowDivIdBackPicture(false);
          //       openModalPicture();
          //     }
          //     : undefined
          // }
          >
            <img
              src={!idFrontPicture ? imgFrontPicture : idFrontPicture}
              className="imgPicture"
              style={{
                width: !idFrontPicture ? '85px' : '200px',
                margin: "0 auto"
              }}
              alt="Frontal cédula"
            />
            <canvas
              id="my-canvas"
              ref={myCanvasRef}
              style={{ display: 'none' }}
            />
            {idFrontPicture && (
              // <i
              //   className="bi bi-trash manual-validation-icon"
              //   onClick={(e) => {
              //     e.stopPropagation();
              //     setIdFrontPicture('');
              //     setIdFrontPictureFile(null);
              //   }}
              // />
              <>
                <br />
                <Iconify sx={{ width: "30px", height: "30px" }} onClick={(e) => {
                  e.stopPropagation();
                  setIdFrontPicture('');
                  // setSelfiePictureFile(null);
                }} icon="eva:trash-2-outline" />
              </>
            )}
            <br />
            {!idFrontPicture ? 'Frontal cédula' : ''}
            <br />
            <Button
              disabled={idFrontPicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "80%", textAlign: "center", margin: "0 auto" }}
              onClick={() => {

                setShowDivSelfiePicture(false);
                setShowDivIdFrontPicture(true);
                setShowDivIdBackPicture(false);
                openModalPicture();
              }}
            >
              Tomar Foto Frontal Cédula con la camara

            </Button>
            <br />

            <Button
              disabled={idFrontPicture ? true : false}
              variant="contained"
              component="label"
              style={{ width: "80%", textAlign: "center" }}
              sx={{ mt: 1 }}
            >
              Subir Foto Frontal Cédula desde el dispositivo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleIdFrontUpload}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
            </Button>
          </Box>
          {/* Botón para cargar foto desde el dispositivo */}

          {/* <Box
            sx={{
              // display: 'flex',
              // flexDirection: 'column',
              textAlign: "center",
              margin: "0 auto",
              alignItems: 'center',

              pt: 2,
              pb: 2,

              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' }
            }}

          >

            <Button
              disabled={idFrontPicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "250px", textAlign: "center", margin: "0 auto" }}
              onClick={() => {

                setShowDivSelfiePicture(false);
                setShowDivIdFrontPicture(true);
                setShowDivIdBackPicture(false);
                openModalPicture();
              }}
            >
              Tomar Foto Frontal Cédula con la camara

            </Button>
            <br />

            <Button
              disabled={idFrontPicture ? true : false}
              variant="contained"
              component="label"
              style={{ width: "250px", textAlign: "center" }}
              sx={{ mt: 1 }}
            >
              Subir Foto Frontal Cédula desde el dispositivo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleIdFrontUpload}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
            </Button>
          </Box> */}
          {/* <Button
            disabled={!idFrontPicture ? true : false}
            variant="contained"
            component="label"
            sx={{ mt: 1 }}
            onClick={handleIdFrontDelete}
          >
            Eliminar Foto Frontal Cédula
          </Button> */}
        </Grid>

        {/* Foto reverso de la cédula */}
        <Grid item xs={12} sm={12} md={12} xl={12}
          sx={{
            // display: 'flex',


            // px: 2,
            maxWidth: { xs: '100%', sm: '100%', md: '100%' },
            margin: '0 auto',
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              margin: "0 auto",
              alignItems: 'center',
              backgroundColor: '#efefef',
              borderRadius: 2,
              border: 1,
              borderColor: 'black',
              p: 2,
              // cursor: 'pointer',
              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' }
            }}
          // onClick={
          //   !idBackPicture
          //     ? () => {
          //       setShowDivSelfiePicture(false);
          //       setShowDivIdFrontPicture(false);
          //       setShowDivIdBackPicture(true);
          //       openModalPicture();
          //     }
          //     : undefined
          // }
          >
            <img
              src={!idBackPicture ? imgBackPicture : idBackPicture}
              className="imgPicture"
              style={{
                width: !idBackPicture ? '85px' : '200px',
                margin: "0 auto"
              }}
              alt="Reverso cédula"
            />
            <canvas
              id="my-canvas2"
              ref={myCanvasRef2}
              style={{ display: 'none' }}
            />
            {idBackPicture && (
              // <i
              //   className="bi bi-trash manual-validation-icon"
              //   onClick={(e) => {
              //     e.stopPropagation();
              //     setIdBackPicture('');
              //     setIdBackPictureFile(null);
              //   }}
              // />
              <>
                <br />
                <Iconify sx={{ width: "30px", height: "30px" }} onClick={(e) => {
                  e.stopPropagation();
                  setIdBackPicture('');
                  // setSelfiePictureFile(null);
                }} icon="eva:trash-2-outline" />
              </>
            )}
            <br />
            {!idBackPicture ? 'Reverso cédula' : ''}
            <br />
            <Button
              disabled={idBackPicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "80%", textAlign: "center", margin: "0 auto" }}
              onClick={() => {

                setShowDivSelfiePicture(false);
                setShowDivIdFrontPicture(false);
                setShowDivIdBackPicture(true);
                openModalPicture();
              }}
            >
              Tomar Foto Reverso Cédula con la camara

            </Button>
            <br />

            <Button
              disabled={idBackPicture ? true : false}
              variant="contained"
              component="label"
              style={{ width: "80%", textAlign: "center" }}
              sx={{ mt: 1 }}
            >
              Subir Foto Reverso Cédula desde el dispositivo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleIdBackUpload}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
            </Button>
          </Box>
          {/* Botón para cargar foto desde el dispositivo */}
          {/* <Box
            sx={{
              // display: 'flex',
              // flexDirection: 'column',
              textAlign: "center",
              margin: "0 auto",
              alignItems: 'center',

              pt: 2,
              pb: 2,
              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' }
            }}

          >
            <Button
              disabled={idBackPicture ? true : false}
              variant="contained"
              component="label"
              sx={{ mt: 1 }}
              style={{ width: "250px", textAlign: "center", margin: "0 auto" }}
              onClick={() => {

                setShowDivSelfiePicture(false);
                setShowDivIdFrontPicture(false);
                setShowDivIdBackPicture(true);
                openModalPicture();
              }}
            >
              Tomar Foto Reverso Cédula con la camara

            </Button>
            <br />

            <Button
              disabled={idBackPicture ? true : false}
              variant="contained"
              component="label"
              style={{ width: "250px", textAlign: "center" }}
              sx={{ mt: 1 }}
            >
              Subir Foto Reverso Cédula desde el dispositivo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleIdBackUpload}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
            </Button>

          </Box> */}
          {/* <Button
            disabled={!idBackPicture ? true : false}
            variant="contained"
            component="label"
            sx={{ mt: 1 }}
            onClick={handleIdBackDelete}
          >
            Eliminar Foto Reverso Cédula
          </Button> */}

        </Grid>
        <Grid item xs={12} textAlign="center">
          <Box mt={2}>
            <Button
              variant="outlined"
              onClick={() => {
                setStorage('request', {
                  ...request,
                  state: 9,
                });
                handleChangeStep(9);
              }}
              sx={{ mr: 2 }}
            >
              Regresar
            </Button>
            {selfiePicture && idFrontPicture && idBackPicture && (
              <Button variant="contained" onClick={validatePictures}>
                Continuar
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Loader */}
      {sending && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Modal */}
      <ModalCreditRequest
        isOpen={isOpenModalPicture}
        closeModal={() => {
          setShowDivSelfiePicture(false);
          setShowDivIdFrontPicture(false);
          setShowDivIdBackPicture(false);
          closeModalPicture();
        }}
      >
        <div style={{ margin: '0 auto' }}>
          <div className="manual-validation-cam">
            <div>
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  width: '100%',
                }}
              >
                <div style={{ width: '100%' }}>
                  <h3 style={{ color: 'gray', marginBottom: '0' }}>
                    {showDivSelfiePicture
                      ? 'Foto selfie'
                      : showDivIdFrontPicture
                        ? 'Foto frontal documento'
                        : showDivIdBackPicture ? 'Foto reverso documento' : ""}
                  </h3>
                  <i
                    className="bi bi-x"
                    style={{
                      cursor: 'pointer',
                      fontSize: '35px',
                      position: 'absolute',
                      right: '5px',
                      top: '7px',
                    }}
                    onClick={() => {
                      setShowDivSelfiePicture(false);
                      setShowDivIdFrontPicture(false);
                      setShowDivIdBackPicture(false);
                      closeModalPicture();
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                margin: '0 auto',
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {showDivSelfiePicture ? (
                <FaceAutoCapture
                  onPhotoTaken={handleFacePhotoTaken}
                  onError={handleError}
                />
              ) : null}

              {showDivIdFrontPicture || showDivIdBackPicture ? (
                <DocumentAutoCapture
                  onPhotoTaken={handleDocumentPhotoTaken}
                  onError={handleError}
                />
              ) : null}
            </div>
          </div>
        </div>
      </ModalCreditRequest>
    </CustomBox>
  );
};

export default IdentityValidation;
